import { apiSlice } from 'shared/store/api';
import {
    FiltersResponse,
    filtersSchema,
    TemplateResponse,
    templateSchema,
    TemplatesResponse,
    templatesSchema,
} from './schemas';
import { ISaveTemplate } from '../types';

const filterApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getFilters: builder.query<FiltersResponse, string>({
            query: reportNumber => `/report/${reportNumber}/filters/`,
            providesTags: ['Filters'],
            extraOptions: {
                error: 'Не удалось загрузить список фильтров!',
                schema: filtersSchema
            }
        }),

        getTemplates: builder.query<TemplatesResponse, string>({
            query: reportNumber => `/report/${reportNumber}/filter_template/`,
            providesTags: ['Templates'],
            extraOptions: {
                error: 'Не удалось загрузить список шаблонов!',
                schema: templatesSchema
            }
        }),

        getTemplate: builder.query<TemplateResponse, {
            reportNumber: string,
            templateId: number
        }>({
            query: args => `/report/${args.reportNumber}/filter_template/${args.templateId}/`,
            providesTags: ['Template'],
            extraOptions: {
                loading: ' ',
                success: 'Шаблон успешно загружен!',
                error: 'Не удалось загрузить шаблон!',
                schema: templateSchema
            }
        }),

        deleteTemplate: builder.mutation<void, {
            reportNumber: string,
            templateId: number
        }>({
            query: args => {
                return ({
                    url: `/report/${args.reportNumber}/filter_template/${args.templateId}/`,
                    method: 'DELETE'
                });
            },
            invalidatesTags: ['Templates'],
            extraOptions: {
                loading: ' ',
                success: 'Шаблон успешно удален!',
                error: 'Не удалось удалить шаблон!'
            }
        }),

        addTemplate: builder.mutation<void, {
            reportNumber: string,
            name: string,
            filters: ISaveTemplate
        }>({
            query: args => {
                // console.log('Filters:', JSON.stringify(args.filters));

                const areAllFiltersSelected = args.filters.filter.every(filter => {
                    return filter.keys && filter.keys.length > 0;
                });

                if (!areAllFiltersSelected) {
                    // Искусственно создаем ошибку
                    throw new Error('Выбраны не все параметры фильтрации');
                }

                return ({
                    url: `/report/${args.reportNumber}/filter_template/`,
                    method: 'POST',
                    body: {
                        name: args.name,
                        json_data: JSON.stringify(args.filters)
                    }
                })
            },
            invalidatesTags: ['Templates'],
            extraOptions: {
                loading: ' ',
                success: 'Шаблон успешно сохранен!',
                error: 'Не удалось сохранить шаблон!'
            }
        }),

        // getExcel: builder.mutation<Blob, { reportNumber: string }>({
        //     query: args => ({
        //         url: `/report/subreport/download/excel/`,
        //         method: 'POST', // Изменяем метод на POST
        //         responseType: 'blob', // Указываем, что ожидаемый тип ответа - blob (файл)
        //     }),
        //     extraOptions: {
        //         loading: ' ',
        //         success: 'Экзел файл загружен!',
        //         error: 'Не удалось загрузить экзел файл!',
        //     },
        // }),
        
    })
})

export const {
    useGetFiltersQuery, useGetTemplatesQuery, useLazyGetTemplateQuery,
    useDeleteTemplateMutation, useAddTemplateMutation
} = filterApiSlice;
