import React, { FC, HTMLAttributes, useCallback, useEffect, useState } from 'react';
import cl from './style.module.css';
import Filter from 'entities/filter/components/Filter';
import Text from 'shared/ui/Text';
import { IAntdOption, IFilter } from 'entities/filter/types';
import { useGetFiltersQuery } from 'entities/filter/model/endpoints';
import { useParams } from 'react-router-dom';
import { FiltersNameEnum } from 'entities/filter/types';
import Spinner from 'shared/ui/Spinner';
import { renameTreeForSelect } from 'entities/filter/utils';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { Modal } from 'antd';

import { setFiltersChain, setTriggerForReportRequest } from 'shared/store/reducers/commonSlice';
import { Button } from 'antd';
import { TemplateResponse } from 'entities/filter/model/schemas';
import { useScreenWidth } from 'shared/hooks/useScreenWidth';
import MovableSidebar from 'shared/ui/MovableSidebar';

interface FiltersProps extends HTMLAttributes<HTMLDivElement> {
    isOpenFilters: boolean;
    setIsOpenFilters: (value: boolean) => void;
    isOpenCities: boolean;
    isOpenFuncGroup: boolean;
    setIsOpenCities: (value: boolean) => void;
    setIsOpenFuncGroup: (value: boolean) => void;
    template?: TemplateResponse;
    triggerTemplate?: boolean;
}

const Filters: FC<FiltersProps> = ({
    isOpenFilters,
    setIsOpenFilters,
    isOpenCities,
    setIsOpenCities,
    isOpenFuncGroup,
    setIsOpenFuncGroup,
    template,
    triggerTemplate
}) => {
    const width = useScreenWidth();
    const dispatch = useAppDispatch();
    const [filtersProps, setFiltersProps] = useState<IFilter[]>([]);
    const { reportNumber } = useParams();
    const { data: filters, isLoading: isLoadingFilters } = useGetFiltersQuery(reportNumber ?? '0');

    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [initialFiltersProps, setInitialFiltersProps] = useState<IFilter[]>([]);
    
    const [isError, setIsError] = useState(false);
    
    const handleFilterChange = useCallback(() => {
        setIsButtonDisabled(false);
    }, []);



    useEffect(() => {
        if (filters) {
            const filteredProps = Object.entries(filters)
                .filter(([key, _]) => key !== 'districts' && key !== 'qual') // Исключаем 'districts' и 'qual'
                .map(([key, value]) => {
                    const arr: IAntdOption[] = [];
                    const options = renameTreeForSelect(value);
                    if (options?.length && Array.isArray(options[0])) {
                        options.forEach(opt => Array.isArray(opt)
                            ? opt.forEach(o => arr.push(o))
                            : arr.push(opt)
                        );
                    }
                    return ({
                        keyForReport: FiltersNameEnum[key as keyof typeof FiltersNameEnum].keyForReport,
                        keyForFilters: key,
                        title: FiltersNameEnum[key as keyof typeof FiltersNameEnum],
                        options: arr.length ? arr : options
                    });
                });
    
            setFiltersProps(filteredProps);
        }
    }, [filters]);



    useEffect(() => {
        dispatch(setFiltersChain(filtersProps.map(fp => fp.keyForReport).join(',')));
    }, [filtersProps]);

    // useEffect(() => {
    //     if(template) {
    //         const chain: string[] = JSON.parse(template.json_data).chain.split(',');
    //         setFiltersProps(chain.map(c => filtersProps.find(fp => fp.keyForReport === c) ?? filtersProps[0]));
    //     }
    // }, [template, triggerTemplate]);
    useEffect(() => {
        if(template && template.json_data) {
            const jsonData = JSON.parse(template.json_data);
            if(jsonData && jsonData.chain) {
                const chain: string[] = jsonData.chain.split(',');
                setFiltersProps(chain.map(c => filtersProps.find(fp => fp.keyForReport === c) ?? filtersProps[0]));
            }
        }
    }, [template, triggerTemplate]);
    

    const cbDrag = useCallback((dragIndex: number, hoverIndex: number) => {
        const dragItem = filtersProps[dragIndex];
        const hoverItem = filtersProps[hoverIndex];

        setFiltersProps(() => {
            const updatedFilters = [...filtersProps];
            updatedFilters[dragIndex] = hoverItem;
            updatedFilters[hoverIndex] = dragItem;

            return [...updatedFilters];
        })
    }, [filtersProps]);

    useEffect(() => {
        // Запомним начальное состояние фильтров
        if (filters && initialFiltersProps.length === 0) {
            setInitialFiltersProps(filtersProps);
        }
    }, [filters, filtersProps]);
    

    useEffect(() => {
        if (JSON.stringify(filtersProps) !== JSON.stringify(initialFiltersProps)) {
            // setIsButtonDisabled(false);
        }
    }, [filtersProps, initialFiltersProps]);
    

    // const handleClick = () => {
    //     dispatch(setTriggerForReportRequest());
    //     setIsButtonDisabled(true);
    //     if (width <= 768) {
    //         setIsOpenFilters(!isOpenFilters);
    //     }
    // }
    
    const handleClick = () => {
        // Предполагаем, что у вас есть состояние filtersProps, в котором хранится информация о выбранных параметрах
        
        let allFiltersSelected = true;

        for (const fp of filtersProps) {
            const localData = localStorage.getItem(`filter_${fp.keyForFilters}`);
        
            if (!localData || JSON.parse(localData).length === 0) {
                allFiltersSelected = false;
                break; // Прерываем цикл, как только найдем невыбранный фильтр
            }
        }

        if (!allFiltersSelected) {
            // Если найден хотя бы один фильтр без выбранных значений, показываем ошибку
            Modal.error({
                title: 'Ошибка',
                content: 'Выбраны не все параметры фильтрации',
            });
            return; // Прекращаем выполнение функции
        }
    
        
    
        setIsError(false); // Сбрасываем ошибку, если все параметры выбраны
        dispatch(setTriggerForReportRequest());
        setIsButtonDisabled(true);
        if (width <= 768) {
            setIsOpenFilters(!isOpenFilters);
        }
    }

    return (
        <MovableSidebar
            className={cl.filters}
            isOpenContent={isOpenFilters}
            setIsOpenContent={setIsOpenFilters}
            showArrowText="Показать фильтры"
            hideArrowText="Скрыть фильтры"
        >
            <div className={cl.innerWrapper}>
                <Text className={cl.title} variant="s" color="primary">Фильтры</Text>
                {isLoadingFilters
                    ?   <div className={cl.spinner}>
                            <Spinner size="s" />
                        </div>
                    :   !!filtersProps.length && filtersProps.map((fp, i) =>
                            <Filter
                                key={fp.keyForFilters}
                                title={fp.title.value}
                                titleKey={fp.keyForFilters}
                                index={i}
                                options={fp.options}
                                cbDrag={cbDrag}
                                template={template}
                                triggerTemplate={triggerTemplate}
                                setIsOpenCitiesSort={() => setIsOpenCities(!isOpenCities)}
                                setIsOpenFuncGroupSort={() => setIsOpenFuncGroup(!isOpenFuncGroup)}
                                onFilterChange={handleFilterChange}
                            />
                    )
                }
            </div>
            <Button size="large" className={cl.applyButton} type="primary" onClick={handleClick} disabled={isButtonDisabled}>
                Сформировать отчет
            </Button>
        </MovableSidebar>
    );
};

export default Filters;
