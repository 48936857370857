import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { List } from 'antd';
import { useGetReportsQuery } from 'entities/report/model/endpoints';
import Spinner from 'shared/ui/Spinner';
import Text from 'shared/ui/Text';
import cl from './style.module.css';
import { Report } from 'entities/report/model/schemas';

const Main: FC = () => {
    const navigate = useNavigate();
    const { data, isLoading, isSuccess, isError, error } = useGetReportsQuery();
    const [reports, setReports] = React.useState<Report[]>([]);  // Инициализация как пустой массив

    useEffect(() => {
        if (isSuccess && data) {
            // Предполагается, что data — это массив объектов
            console.log("Data received:", data);  // Выводим данные в консоль для проверки

            const reportList = Array.isArray(data) ? data : data.reports;  // Проверка типа данных
            console.log("Report List:", reportList);  // Выводим список отчетов

            if (reportList && reportList.length > 0) {
                setReports(reportList);

                // Если есть только один отчет, сразу перенаправляем
                if (reportList.length === 1) {
                    navigate(`/report/${reportList[0].id}`);
                }
            }
        }
    }, [isSuccess, data, navigate]);

    if (isLoading) {
        return <Spinner size="m" />;
    }

    if (isError) {
        return <Text variant="s" color="primary">Произошла ошибка при загрузке отчетов</Text>;
    }

    return (
        <div className={cl.wrapper}>
            {reports.length > 0 ? (
                <List
                    className={cl.list}
                    size="large"
                    header={<Text variant="m" color="primary" bold>Список отчетов</Text>}
                    bordered
                    dataSource={reports}
                    renderItem={(item: Report) => (
                        <List.Item key={item.id}>
                            <a className={cl.link} href={`/report/${item.id}`}>
                                <Text variant="s" color="primary">{item.name}</Text>
                            </a>
                        </List.Item>
                    )}
                />
            ) : (
                <Text variant="s" color="primary">Отчеты не доступны</Text>
            )}
        </div>
    );
};

export default Main;

